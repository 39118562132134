import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BoardService } from 'src/app/board/board.service';
import { first, Subject, takeUntil } from 'rxjs';
import {
  DashboardData,
  FolderDashboardData,
  UpdateOrderDashboard,
  RoleDashboardMenu,
  UserAccountInfo,
} from 'src/models';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/user.service';
import { MobileBrowserChecker, TermsGeneric } from 'src/helpers';
import { PopupService } from 'src/app/core/popup.service';
import { FolderService } from 'src/app/board/folder.service';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { RightClickMenuDirective } from 'src/helpers/directives/right-click-menu/right-click-menu.directive';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { AvatarFileComponent } from 'src/app/shared/avatar-file/avatar-file.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OptionsMenuDrawerComponent } from 'src/app/navigation/menu/options-menu-drawer/options-menu-drawer.component';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';

/** Folder menu expanded. */
interface FolderExpanded {
  /** If folder is expandable. */
  [key: string]: boolean;
}

/**
 * Expansion menu for dashboard menu drawer.
 */
@Component({
  selector: 'app-list-board-menu-drawer[dashboardRole]',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    RightClickMenuDirective,
    AvatarFileComponent,
    OptionsMenuDrawerComponent,
    LoadingIndicatorComponent,
  ],
  templateUrl: './list-board-menu-drawer.component.html',
  styleUrls: ['./list-board-menu-drawer.component.scss'],
})
export class ListBoardMenuDrawerComponent implements OnInit, OnDestroy {
  /** Type dashboard. */
  @Input() dashboardRole!: RoleDashboardMenu | 'bookmarked';

  /** Edit mode menu. */
  @Input() editMode = false;

  /** Manage members update treatment. */
  @Input() flagManagementMembersUpdate = false;

  /** Search. */
  search = '';

  /** Status expanded folder. */
  expanded: FolderExpanded = {};

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Rithm id get for params.*/
  paramRithmId!: string | null;

  /** Loading for list menu. */
  isLoading = false;

  /** Show error in list the dashboard. */
  showError = false;

  /** Show error in list the dashboard. */
  isPrincipalPageDashboard = false;

  /** Dashboards list. */
  dashboardsList: DashboardData[] = [];

  /** Copy to restore dashboards list. */
  copyDashboardsList: DashboardData[] = [];

  /** Folder dashboards list. */
  folderDashboardData: FolderDashboardData[] = [];

  /** Copy to restore folder dashboards list. */
  copyFolderDashboardData: FolderDashboardData[] = [];

  /** Validate type of role. */
  enumRoleDashboardMenu = RoleDashboardMenu;

  /** The current rithmId of the open board. */
  currentBoardId = '';

  /** True if the user is architect else False. */
  isArchitect = false;

  /** Error getting folders. */
  isErrorGettingFolders = false;

  /** Loading getting folders. */
  isLoadingFolders = false;

  /** If An error has occurred to update dashboards/folder order. */
  isErrorUpdating = false;

  /** Terms generic. */
  termsGeneric = TermsGeneric;

  /** Service of sidenavDrawer's. */
  private sidenavDrawerService: SidenavDrawerService =
    inject(SidenavDrawerService);

  constructor(
    private boardService: BoardService,
    public router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    public mobileBrowserChecker: MobileBrowserChecker,
    private popupService: PopupService,
    private folderService: FolderService,
  ) {}

  /** Subscribe to currentActiveBoardId to get rithmId. */
  private subscribeCurrentActiveBoardId$(): void {
    this.boardService.currentActiveBoardId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((boardId) => {
        this.currentBoardId = boardId;
      });
  }

  /** Init live cycle component. */
  ngOnInit(): void {
    this.isArchitect = this.userService.isAdmin();
    this.getParams();
    this.isPrincipalPageDashboard =
      this.router.url === `/${TermsGeneric.Board.Lower.Plural}`;
    this.subscribeCurrentActiveBoardId$();
    if (this.dashboardRole !== 'bookmarked') {
      this.getToListDashboards();
    } else {
      this.getBookmarkedDashboardAndFolders();
    }
    this.dashboardRole === this.enumRoleDashboardMenu.Personal &&
      this.getFolderListDashboard();
    this.subscribeReloadListMenuDashboard$();
  }

  /** Get params for this path. */
  private getParams(): void {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params) => {
        if (params.boardId) {
          this.paramRithmId = params.boardId;
        }
      },
    });
  }

  /**
   * Subscribe to reload list by actions in options.
   */
  private subscribeReloadListMenuDashboard$(): void {
    this.boardService.reloadListMenuDashboard$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (reload) => {
          if (reload.roleDashboardMenu.includes(this.dashboardRole)) {
            this.dashboardsList.forEach((dashboard) => {
              dashboard.rithmId === reload.rithmId &&
                (dashboard.isBookmarked = reload.isBookmark);
            });
          }
          if (
            reload.roleDashboardMenu.includes(
              this.enumRoleDashboardMenu.Personal,
            )
          ) {
            this.folderDashboardData.forEach((folder) => {
              folder.rithmId === reload.rithmId &&
                (folder.isBookmarked = reload.isBookmark);
              folder.dashboards.forEach((dashboard) => {
                dashboard.rithmId === reload.rithmId &&
                  (dashboard.isBookmarked = reload.isBookmark);
              });
            });
          }

          if (reload.roleDashboardMenu.includes('bookmarked')) {
            if (reload.isUpdate && reload.isFolder) {
              const index = this.folderDashboardData.findIndex(
                ({ rithmId }) => rithmId === reload.rithmId,
              );
              if (index > -1) {
                if (!reload.data) {
                  this.folderDashboardData[index].isBookmarked =
                    reload.isBookmark;
                } else {
                  this.folderDashboardData[index] =
                    reload.data as FolderDashboardData;
                  this.copyFolderDashboardData[index] =
                    reload.data as FolderDashboardData;
                }
              }
            } else {
              this.dashboardRole === 'bookmarked' &&
                this.getBookmarkedDashboardAndFolders();
            }
          }
        },
      });
  }

  /**
   * Drag and Drop change order in folder.
   * @param event Drag and Drop event.
   */
  dropFolder(event: CdkDragDrop<FolderDashboardData[]>): void {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
  }

  /**
   * Drag and Drop change dashboards.
   * @param event Drag and Drop event.
   */
  dropDashboard(event: CdkDragDrop<DashboardData[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  /** Get list to dashboard in expansion menu. */
  private getToListDashboards(): void {
    if (this.dashboardRole !== 'bookmarked') {
      this.showError = false;
      this.isLoading = true;
      const petitionDashboard$ =
        this.dashboardRole === this.enumRoleDashboardMenu.Company
          ? this.boardService.getOrganizationDashboard()
          : this.boardService.getPersonalDashboard();

      petitionDashboard$.pipe(first()).subscribe({
        next: (dashboards) => {
          this.dashboardsList = dashboards;
          this.makeCopyMenuData(true);
          this.showError = false;
          this.isLoading = false;
        },
        error: () => {
          this.showError = true;
          this.isLoading = false;
        },
      });
    }
  }

  /**
   * Get dashboards and folders bookmarked.
   */
  private getBookmarkedDashboardAndFolders(): void {
    if (this.dashboardRole === 'bookmarked') {
      this.showError = false;
      this.isLoading = true;
      this.isErrorGettingFolders = false;
      this.isLoadingFolders = true;
      this.boardService
        .getBookmarkedDashboardAndFolders(true)
        .pipe(first())
        .subscribe({
          next: (dataBookmark) => {
            this.dashboardsList = dataBookmark.dashboards;
            this.folderDashboardData = dataBookmark.folders;

            this.makeCopyMenuData(true, true);
            this.showError = false;
            this.isLoading = false;
            this.isErrorGettingFolders = false;
            this.isLoadingFolders = false;
          },
          error: () => {
            this.showError = true;
            this.isLoading = false;
            this.isErrorGettingFolders = true;
            this.isLoadingFolders = false;
          },
        });
    }
  }

  /**
   * Copy dashboardList.
   * @param isDashboard Identify if the copy is for dashboards.
   * @param isFolder Identify if the copy is for folders.
   */
  makeCopyMenuData(isDashboard = false, isFolder = false): void {
    if (isDashboard) {
      this.copyDashboardsList = JSON.parse(JSON.stringify(this.dashboardsList));
    }

    if (isFolder) {
      this.copyFolderDashboardData = JSON.parse(
        JSON.stringify(this.folderDashboardData),
      );
    }
  }

  /** Get folder list dashboard. */
  private getFolderListDashboard(): void {
    this.isErrorGettingFolders = false;
    this.isLoadingFolders = true;
    this.folderService
      .getFolderListDashboard(true)
      .pipe(first())
      .subscribe({
        next: (folderDashboardData) => {
          this.folderDashboardData = folderDashboardData;
          this.setFolderExpanded(folderDashboardData);
          this.makeCopyMenuData(false, true);

          this.isErrorGettingFolders = false;
          this.isLoadingFolders = false;
        },
        error: () => {
          this.isErrorGettingFolders = true;
          this.isLoadingFolders = false;
        },
      });
  }

  /**
   * Go to dashboard.
   * @param dashboardRithmId RithmId.
   */
  goToDashboard(dashboardRithmId: string): void {
    if (!this.editMode) {
      const path = ['/', TermsGeneric.Board.Lower.Plural];
      path.push(dashboardRithmId);
      this.router.navigate(path);
      this.sidenavDrawerService.closeSidenav();
    }
  }

  /** Update order the dashboards for bookmarked, org and personal. */
  updateOrderListDashboard(): void {
    this.isLoadingFolders =
      JSON.stringify(this.folderDashboardData) !==
      JSON.stringify(this.copyFolderDashboardData);

    this.isLoading =
      JSON.stringify(this.dashboardsList) !==
      JSON.stringify(this.copyDashboardsList);
    const role = {
      bookmarked: 'bookmarked',
      [this.enumRoleDashboardMenu.Company]: 'org',
      [this.enumRoleDashboardMenu.Personal]: 'personal',
    };
    this.boardService
      .updateDashboardsFolderOrder(
        role[this.dashboardRole] as 'org' | 'personal' | 'bookmarked',
        this.getOrderDashboardList(this.dashboardsList),
        this.getOrderFolderList(this.folderDashboardData),
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.boardService.reloadListMenuDashboard({
            roleDashboardMenu: ['bookmarked'],
            isBookmark: false,
            rithmId: '',
            isFolder: false,
          });
          this.makeCopyMenuData(true, true);
          this.isLoading = false;
          this.isLoadingFolders = false;
        },
        error: () => {
          this.isErrorUpdating = true;

          setTimeout(() => {
            this.isErrorUpdating = false;
          }, 5000);

          this.isLoading = false;
          this.isLoadingFolders = false;
          this.restoreMenuData();
        },
      });
  }

  /**
   * Notify and show pop-up for actions the dashboard.
   * @param message Message for show in popup.
   * @param isError If the message if error.
   */
  private notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }

  /**
   * Initiate a confirmation popup for either dashboard delete methods.
   * @param isFolder Param for detect is element to delete is folder.
   * @param element Param contain data or folder.
   */
  async confirmActionDelete(
    isFolder = false,
    element: DashboardData | FolderDashboardData,
  ): Promise<void> {
    const response = await this.popupService.confirm({
      title:
        'Delete "' +
        (isFolder
          ? `${element.name}" folder?`
          : `${element.name}" ${TermsGeneric.Board.Lower.Single}?`),
      message: 'This cannot be undone!',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });

    if (response && !isFolder) {
      this.deleteDashboard(element as DashboardData);
    } else if (response && isFolder) {
      this.deleteFolderDashboard(element as FolderDashboardData);
    }
  }

  /**
   * Delete dashboard.
   * @param dashboard The dashboard to delete.
   */
  deleteDashboard(dashboard: DashboardData): void {
    // Whether it is a child board within a folder or not.
    let isBoardChild = false;
    if (dashboard.type === this.enumRoleDashboardMenu.Personal) {
      const rithmIdToFind = dashboard.rithmId;
      const foundDashboard = this.folderDashboardData
        .flatMap((folder) => folder.dashboards)
        .find((dashboardItem) => dashboardItem.rithmId === rithmIdToFind);
      // If we find inside the folders the board.
      if (foundDashboard) {
        isBoardChild = true;
      }
    }
    // We load the folders otherwise we load the index boards.
    if (isBoardChild) {
      this.isLoadingFolders = true;
    } else {
      this.isLoading = true;
    }
    const isDefaultDashboard =
      dashboard.rithmId === this.userService.user().defaultDashboardId;
    const isCurrentDashboard = dashboard.rithmId === this.paramRithmId;
    const isCurrentPrincipalDashboard = this.paramRithmId === null;
    /* Index is principal dashboard when is 0 this is specified in dashboard-component getOrganizationDashboard
      if this value is modified should modified this condition. */
    const isPrincipalDashboard =
      this.isPrincipalPageDashboard &&
      this.dashboardsList.some(
        ({ rithmId }, i) =>
          dashboard.type === this.enumRoleDashboardMenu.Company &&
          rithmId === dashboard.rithmId &&
          i === 0,
      );

    if (
      isCurrentDashboard ||
      isDefaultDashboard ||
      (isCurrentPrincipalDashboard && isPrincipalDashboard)
    ) {
      this.boardService.toggleLoadingDashboard(true);
    }
    const deleteDashboard$ =
      this.dashboardRole === this.enumRoleDashboardMenu.Company
        ? this.boardService.deleteOrganizationDashboard(dashboard.rithmId)
        : this.boardService.deletePersonalDashboard(dashboard.rithmId);

    deleteDashboard$.pipe(first()).subscribe({
      next: () => {
        if (isDefaultDashboard) {
          this.setDefaultDashboard(true, dashboard);
        }
        //boardService.toggleLoadingDashboard is to reload dashboard component
        else if (isCurrentPrincipalDashboard && isPrincipalDashboard) {
          this.boardService.toggleLoadingDashboard(false, true);
        } else if (isCurrentDashboard) {
          this.router.navigate(['/', TermsGeneric.Board.Lower.Plural]);
        }
        this.notify(
          `${TermsGeneric.Board.Title.Single} ` +
            dashboard.name +
            ' removed successfully',
        );
        this.boardService.reloadListMenuDashboard({
          roleDashboardMenu: ['bookmarked'],
          isBookmark: false,
          rithmId: '',
          isFolder: false,
        });
        this.makeCopyMenuData(true, true);
        this.getBookmarkedDashboardAndFolders();
        if (isBoardChild) {
          // If it is a child dashboard, we need to remove it from the dashboard array.
          const rithmIdToRemove = dashboard.rithmId;
          this.folderDashboardData.forEach((folder) => {
            folder.dashboards = folder.dashboards.filter(
              (d) => d.rithmId !== rithmIdToRemove,
            );
          });
          this.isLoadingFolders = false;
        } else {
          this.isLoading = false;
        }
      },
      error: () => {
        if (
          isCurrentDashboard ||
          (isCurrentPrincipalDashboard && isPrincipalDashboard)
        ) {
          this.boardService.toggleLoadingDashboard(false, true);
        }
        this.notify(
          'Unable to remove ' +
            dashboard.name +
            ` ${TermsGeneric.Board.Title.Single}`,
          true,
        );
        if (isBoardChild) {
          this.isLoadingFolders = true;
        } else {
          this.isLoading = true;
        }
      },
      complete: () => {
        this.getToListDashboards();
      },
    });
  }

  /**
   * Set default dashboard.
   * @param clearDashboard If dashboard delete is default dashboard.
   * @param dashboard Dashboard for establishing how default dashboard.
   */
  setDefaultDashboard(clearDashboard = false, dashboard: DashboardData): void {
    const defaultDashboard: UserAccountInfo = {
      defaultDashboardType: clearDashboard ? '' : dashboard.type,
      defaultDashboardId: clearDashboard ? '' : dashboard.rithmId,
    };

    this.userService
      .updateUserAccount(defaultDashboard)
      .pipe(first())
      .subscribe({
        next: () => {
          if (clearDashboard) {
            this.boardService.toggleLoadingDashboard(false, true);
          }
          this.notify(
            `New ${TermsGeneric.Board.Lower.Single} ` +
              `${dashboard.name} set as default.`,
          );
        },
        error: () => {
          if (clearDashboard) {
            this.boardService.toggleLoadingDashboard(false, true);
          }
          this.notify(
            `Unable to set ${dashboard.name} ` +
              `${TermsGeneric.Board.Lower.Single} as default.`,
            true,
          );
        },
      });
  }

  /**
   * Delete folder in dashboard.
   * @param folder Folder data for delete.
   */
  private deleteFolderDashboard(folder: FolderDashboardData): void {
    this.isLoadingFolders = true;
    this.folderService
      .deleteFolderDashboard(folder.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.notify(folder.name + ' Folder Removed.');
          this.boardService.reloadListMenuDashboard({
            roleDashboardMenu: ['bookmarked'],
            isBookmark: false,
            rithmId: '',
            isFolder: true,
          });
          this.isLoadingFolders = false;
        },
        error: () => {
          this.notify('Unable to remove ' + folder.name + ' Folder', true);
          this.isLoadingFolders = false;
        },
        complete: () => {
          this.getFolderListDashboard();
        },
      });
  }

  /**
   * Add new folder for list dashboards.
   */
  addNewFolderDashboard(): void {
    this.isLoadingFolders = true;
    this.folderService
      .addNewFolderDashboard()
      .pipe(first())
      .subscribe({
        next: () => {
          this.notify('New folder successfully created');
          this.isLoadingFolders = false;
        },
        error: () => {
          this.notify('New folder could not be created', true);
          this.isLoadingFolders = false;
        },
        complete: () => {
          this.getFolderListDashboard();
        },
      });
  }

  /**
   * Return array with connections for drag and drop in folders.
   * @param folderRithmId Folder rithmId.
   * @returns Array connections.
   */
  connectTo(folderRithmId: string): string[] {
    const rithmIdsConnections: string[] = [];
    this.folderDashboardData.forEach(({ rithmId }) => {
      rithmId !== folderRithmId &&
        rithmIdsConnections.push(rithmId + '-' + this.dashboardRole);
    });

    folderRithmId && rithmIdsConnections.push('dashboard-list-personal');
    return rithmIdsConnections;
  }

  /**
   * Set values expanded status.
   * @param folders Folders.
   * @param expanded Is expanded.
   */
  setFolderExpanded(folders: FolderDashboardData[], expanded = false): void {
    this.expanded = {};
    folders.map((folder) => {
      this.expanded = {
        ...this.expanded,
        [folder.rithmId]: expanded,
      };
    });
  }

  /**
   * Restore data menu when cancel editMode.
   */
  restoreMenuData(): void {
    this.folderDashboardData = JSON.parse(
      JSON.stringify(this.copyFolderDashboardData),
    );
    this.dashboardsList = JSON.parse(JSON.stringify(this.copyDashboardsList));
    this.setFolderExpanded(this.folderDashboardData);
  }

  /**
   * Get new order dashboard list.
   * @param dashboardList Dashboards list to sort.
   * @returns Order dashboard list.
   */
  getOrderDashboardList(
    dashboardList: DashboardData[],
  ): UpdateOrderDashboard[] {
    return dashboardList.map((F, index) => {
      return {
        rithmId: F.rithmId,
        order: index + 1,
      };
    });
  }

  /**
   * Get new order folder list.
   * @param folderList Folder list to sort.
   * @returns Order folder list.
   */
  getOrderFolderList(
    folderList: FolderDashboardData[],
  ): UpdateOrderDashboard[] {
    return folderList.map((F, index) => {
      return {
        rithmId: F.rithmId,
        order: index + 1,
        dashboards: this.getOrderDashboardList(F.dashboards),
      };
    });
  }

  /** Clean subscriptions. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
