import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { first, Subject } from 'rxjs';
import {
  AdminImageModalTypeEnum,
  DashboardData,
  FolderDashboardData,
  OrientationDashboard,
  RoleDashboardMenu,
} from 'src/models';
import { BoardService } from 'src/app/board/board.service';
import { PopupService } from 'src/app/core/popup.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ManagementMemberBoardModalComponent } from 'src/app/board/management-member-board-modal/management-member-board-modal/management-member-board-modal.component';
import { UserService } from 'src/app/core/user.service';
import { AdminImageModalComponent } from 'src/app/shared/admin-image-modal/admin-image-modal.component';
import { FolderService } from 'src/app/board/folder.service';
import { Router } from '@angular/router';
import { TermsGeneric } from 'src/helpers';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';

/**
 * Options menu for dashboard menu drawer.
 */
@Component({
  selector: 'app-options-menu-drawer[dashboardRole][isArchitect]',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './options-menu-drawer.component.html',
  styleUrls: ['./options-menu-drawer.component.scss'],
})
export class OptionsMenuDrawerComponent implements OnDestroy {
  /** Allows functionality of MatMenu to toggle the menu open. */
  @ViewChild(MatMenuTrigger)
  private optionsMenuTrigger!: MatMenuTrigger;

  /** Dashboard type from list-board-menu. */
  @Input() dashboardRole!: RoleDashboardMenu | 'bookmarked';

  /** Show option. */
  @Input() isDashboardListOptions!: boolean;

  /** Is Principal Page Dashboard. */
  @Input() isPrincipalPageDashboard = false;

  /** Disable set defaultDashboard if the current dashboard is default. */
  @Input() isDefaultDashboard = false;

  /** Manage members update treatment. */
  @Input({ required: true }) flagManagementMembersUpdate = false;

  /** Dashboard dashboardData. */
  @Input() dashboardData: DashboardData = {
    rithmId: '',
    orientation: OrientationDashboard.Dashboard,
    type: RoleDashboardMenu.Company,
    name: '',
    widgets: [],
    isEditable: false,
    canView: false,
    isBookmarked: false,
    order: 1,
    userRithmId: '',
  };

  /** Folder data. */
  @Input() folderData!: FolderDashboardData;

  /** If options are folder. */
  @Input() isFolder = false;

  /** If user has architect role or not. */
  @Input({ required: true }) isArchitect = false;

  /** Event for show confirm action. */
  @Output() eventConfirmDelete = new EventEmitter<{
    /** Is folder menu show options for folder or dashboard. */
    isFolder: boolean;
    /** Element for send element dashboard or folder selected. */
    element: DashboardData | FolderDashboardData;
  }>();

  /** Set dashboard how default. */
  @Output() eventSetDefaultDashboard = new EventEmitter<DashboardData>();

  /** Event for generate new folder. */
  @Output() eventAddNewFolderDashboard = new EventEmitter<boolean>();

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Display or not mat menu when its generate new dashboard. */
  isGenerateNewDashboard = false;

  /** Validate type of role. */
  enumRoleDashboardMenu = RoleDashboardMenu;

  /** Enum orientation dashboard. */
  enumOrientationDashboard = OrientationDashboard;

  /** Terms generic. */
  termsGeneric = TermsGeneric;

  constructor(
    private boardService: BoardService,
    private router: Router,
    private popupService: PopupService,
    private dialog: MatDialog,
    public userService: UserService,
    private folderService: FolderService,
    private sidenavDrawerService: SidenavDrawerService,
  ) {}

  /**
   * Opens the option menu on the dashboard menu.
   * @param event The click event.
   */
  openOptionsMenu(event: MouseEvent): void {
    this.optionsMenuTrigger.openMenu();
    event.stopPropagation();
  }

  /**
   * Generate a new dashboard.
   * @param orientation Orientation of dashboard.
   */
  generateNewDashboard(orientation: OrientationDashboard): void {
    this.isGenerateNewDashboard = true;
    this.boardService.toggleLoadingDashboard(true);
    const generateDashboard$ =
      this.dashboardRole === this.enumRoleDashboardMenu.Personal
        ? this.boardService.generateNewPersonalDashboard(
            true,
            true,
            orientation,
          )
        : this.boardService.generateNewOrganizationDashboard(
            true,
            this.isArchitect,
            orientation,
          );
    generateDashboard$.pipe(first()).subscribe({
      next: (newDashboard) => {
        this.isGenerateNewDashboard = false;
        this.boardService.toggleLoadingDashboard(false);
        this.router.navigate(
          ['/', TermsGeneric.Board.Lower.Plural, newDashboard.rithmId],
          {
            queryParams: { editMode: true },
          },
        );
        this.notify(`${TermsGeneric.Board.Title.Single} created successfully`);
        this.sidenavDrawerService.closeSidenav();
      },
      error: () => {
        this.boardService.toggleLoadingDashboard(false);
        this.isGenerateNewDashboard = false;
        this.notify(
          `Unable to create a new ${TermsGeneric.Board.Lower.Single}`,
          true,
        );
      },
    });
  }

  /** Open dialog add widget. */
  openDialogManagementMembers(): void {
    const panelClass = this.flagManagementMembersUpdate
      ? ['w-[750px]', 'h-[750px]']
      : ['w-5/6', 'sm:w-3/5', 'h-[95vh]', 'sm:h-[80vh]', 'set-overflow-hidden'];

    this.dialog.open(ManagementMemberBoardModalComponent, {
      panelClass,
      maxWidth: '1000px',
      maxHeight: '1000px',
      disableClose: true,
      data: {
        rithmId: this.dashboardData.rithmId || this.folderData.rithmId,
        dashboardType: this.dashboardRole,
        isFolder: this.isFolder,
        ownUserRithmId: this.dashboardData.userRithmId || '',
        flagManagementMembersUpdate: this.flagManagementMembersUpdate,
        entityName: !this.isFolder
          ? this.dashboardData.name
          : this.folderData.name,
      },
    });
  }

  /**
   * Open modal admin-image-modal.
   */
  openAdminImageModal(): void {
    this.dialog
      .open(AdminImageModalComponent, {
        panelClass: ['w-11/12', 'sm:w-4/5', 'lg:w-[45%]'],
        maxWidth: '1500px',
        disableClose: true,
        data: {
          rithmId: this.folderData.rithmId,
          photoVaultId: this.folderData.imageId,
          typeModal: AdminImageModalTypeEnum.Folder,
          dataModal: this.folderData, // this param is required for this component.
          icon: 'fa-light fa-folder',
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((folderDashboardData: FolderDashboardData) => {
        folderDashboardData &&
          this.boardService.reloadListMenuDashboard({
            roleDashboardMenu: ['bookmarked'],
            isBookmark: true,
            rithmId: folderDashboardData.rithmId,
            isFolder: true,
            data: folderDashboardData,
            isUpdate: true,
          });
      });
  }

  /**
   * Add dashboard bookMarked.
   * @param addBookmarked Param for delete or add dashboard bookmark.
   */
  addOrDeleteDashboardOrFolderBookmarked(addBookmarked: boolean): void {
    const bookmarked$ = this.isFolder
      ? this.folderService.addOrDeleteFolderBookmarked(this.folderData.rithmId)
      : this.boardService.addOrDeleteDashboardBookmarked(
          this.dashboardData.rithmId,
        );

    bookmarked$.pipe(first()).subscribe({
      next: () => {
        const roleDashboardMenu: (RoleDashboardMenu | 'bookmarked')[] =
          addBookmarked
            ? [this.dashboardRole]
            : [RoleDashboardMenu.Company, RoleDashboardMenu.Personal];
        this.boardService.reloadListMenuDashboard({
          roleDashboardMenu: ['bookmarked', ...roleDashboardMenu],
          isBookmark: addBookmarked,
          rithmId: this.isFolder
            ? this.folderData.rithmId
            : this.dashboardData.rithmId,
          isFolder: this.isFolder,
        });
        this.notify(
          addBookmarked
            ? (this.isFolder
                ? `Folder ${this.folderData.name}`
                : `${TermsGeneric.Board.Title.Single} ${this.dashboardData.name}`) +
                ' set as bookmarked.'
            : 'Remove bookmarked ' +
                (this.isFolder
                  ? `folder ${this.folderData.name} `
                  : `${TermsGeneric.Board.Lower.Single} ${this.dashboardData.name}`) +
                ' successfully.',
        );
      },
      error: () => {
        this.notify(
          addBookmarked
            ? 'Unable to add ' +
                (this.isFolder
                  ? `Folder ${this.folderData.name}`
                  : `${TermsGeneric.Board.Title.Single} ${this.dashboardData.name}`) +
                ' as bookmarked.'
            : `Unable to remove bookmarked ${TermsGeneric.Board.Lower.Single} ` +
                (this.isFolder
                  ? `Folder ${this.folderData.name}.`
                  : `${TermsGeneric.Board.Title.Single} ${this.dashboardData.name}.`),
          true,
        );
      },
    });
  }

  /**
   * Notify and show pop-up for actions the dashboard.
   * @param message Message for show in popup.
   * @param isError If the message if error.
   */
  private notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }

  /** Clean subscriptions. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
